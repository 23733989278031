.pagination-container {
  display: flex;
  justify-content: left;
  // align-items: center;
  // margin-bottom: 20px; // Optional: Add margin for spacing

  .pagination,
  .rows-per-page {
    display: flex;
    align-items: center;
  }

  .rows-per-page {
   // margin-left: 20px; // Add margin to create space between pagination and dropdown
    label {
     // margin-right: 10px; // Add margin for spacing between label and dropdown
     margin-right: 10px;
      //padding-right: 20px;
    }
    margin-bottom: 60px;
  }
    .pagination-nav{
      height: 30px !important;
      position: relative !important;
      margin-bottom: 70px !important;
      max-width: fit-content;
      margin-top: 05px !important;
      padding-left: 20px !important;
    }
  }

